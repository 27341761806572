<template>
  <div class="forgetpwd">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-9">
          <div class="text-primary mt-md-5">
            <i class="bi bi-question-circle"></i> 忘記密碼
          </div>
          <div
            class="row mt-md-1 border-top border-bottom border-2 py-md-4 d-md-flex justify-content-md-between"
          >
            <div class="col-md-6">
              <div class="text pe-md-5 pt-3 pt-md-0">
                <ul>
                  <li>請填寫email/手機號碼/身份證字號任一個資訊後送出，系統將寄出重新啟用帳號的訊息至您的信箱或手機。</li>
                </ul>
              </div>
            </div>
            <div class="col-md-6">
              <Form
                class="form ps-md-5 align-self-stretch d-flex flex-column mt-3 mt-md-0"
                v-slot="{ errors }"
                @submit="confirm"
              >
                <div class="mb-4">
                  <p class="mb-md-1">會員帳號</p>
                  <Field
                    type="text"
                    name="忘記密碼"
                    v-model="MemberCode"
                    :class="{ 'is-invalid': errors['忘記密碼'] }"
                    class="form-control z-index-10"
                    :rules="forgetPassword"
                    id="exampleFormControlInput1"
                    placeholder="請輸入 手機號碼 或 email 或 身分證字號"
                  ></Field>
                  <ErrorMessage name="忘記密碼" class="invalid-feedback"></ErrorMessage>
                </div>
                <p class="mb-md-1">驗證碼</p>
                <div class="flex-grow-1 mb-3">
                  <div class="input-group">
                    <Field
                      :class="{ 'is-invalid': errors['驗證碼'] }"
                      type="text"
                      name="驗證碼"
                      class="form-control z-index-10"
                      v-model="forgetPass.ValidCode"
                      maxlength="4"
                      tabindex="3"
                      autocomplete="off"
                      rules="required"
                      placeholder="請輸入驗證碼"
                    ></Field>
                    <button
                      class="verifyBtn btn border"
                      type="button"
                      @click.prevent="getRandomNum"
                    >
                      <img
                        src=""
                        id="imgForgetPasswordValidCode"
                        style="width: 80px; height: 30px; margin-right: 8px;"
                      />
                      <!-- <img src="../assets/img/yesgo_icon27.svg" /> -->
                    </button>
                    <ErrorMessage name="驗證碼" class="invalid-feedback"></ErrorMessage>
                  </div>
                  <div class="d-flex justify-content-end text-muted fs-7">
                    驗證碼不須區分大小寫
                  </div>
                </div>
                <button type="submit" class="btn btn-primary text-light w-100">
                  確認送出
                </button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ValidateSvc from '@/service/validate-service.js'
import PwdSvc from '@/service/pwd-service.js'

export default {
  data () {
    return {
      MemberCode: '',
      forgetPass: {
        ValidCode: '',
        ValidTransactionId: ''
      },
      postToAPI: {
        MemberCode: '',
        Captcha: '',
        ValidTransactionId: ''
      }
    }
  },
  methods: {
    confirm (value, { resetForm }) {
      const id = /^[a-z](1|2)\d{8}$/i
      if (id.test(this.MemberCode)) {
        this.MemberCode = this.MemberCode.toUpperCase()
      }
      this.postToAPI.MemberCode = this.MemberCode
      this.postToAPI.Captcha = this.forgetPass.ValidCode
      this.postToAPI.ValidTransactionId = this.forgetPass.ValidTransactionId
      PwdSvc.ForgetPwd(this.postToAPI)
        .then((SvcData) => {
          resetForm() // ?把表單重置成預設值
          this.getRandomNum()
          if (!SvcData.isSuccess) {
            this.$swal.fire({
              title: `${SvcData.message}(${SvcData.rtnCode})`,
              allowOutsideClick: true,
              confirmButtonColor: '#003894',
              confirmButtonText: '確認',
              width: 500,
              customClass: {
                title: 'text-class',
                confirmButton: 'confirm-btn-class'
              }
            })
            return
          }
          this.$swal.fire({
            title: '我們已經將查詢資料寄送到您填寫的手機號碼或 Email!',
            allowOutsideClick: true,
            confirmButtonColor: '#003894',
            confirmButtonText: '確認',
            width: 500,
            customClass: {
              title: 'text-class',
              confirmButton: 'confirm-btn-class'
            }
          })
        })
    },
    // * 帳號表單驗證
    forgetPassword (value) {
      return ValidateSvc.CheckAccount(value)
    },
    // * 圖形驗證碼
    getRandomNum () {
      ValidateSvc.GetValidateCode()
        .then((SvcData) => {
          this.forgetPass.ValidTransactionId = SvcData.transactionId
          document.getElementById('imgForgetPasswordValidCode').src = SvcData.validateImg
        })
    }
  },
  mounted () {
    this.getRandomNum()
  }
}
</script>
<style lang="scss" scoped>
  .container {
    min-height: calc(100vh - 300px);
  }
</style>
